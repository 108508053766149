import React, { ChangeEvent, FC } from 'react';
import type { IBuilderStepInputCheckListOption } from '@workerbase/domain/document';
import { Checkbox, IconButton, Radio, Stack, TextField } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

interface BuilderStepInputChecklistOptionProps {
  index: number;
  value: IBuilderStepInputCheckListOption;
  multiple: boolean;
  isEditMode: boolean;
  isChecked?: boolean;
  onChange: (value: IBuilderStepInputCheckListOption) => void;
  onDelete: () => void;
}

export const BuilderStepInputChecklistOption: FC<BuilderStepInputChecklistOptionProps> = ({
  index,
  value,
  multiple,
  isChecked = false,
  isEditMode = false,
  onChange,
  onDelete,
}) => {
  const handleTextChange = () => (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    onChange({ value: text, label: text, _id: value._id });
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {multiple ? <Checkbox checked={isChecked} disabled /> : <Radio checked={isChecked} disabled />}
      <TextField
        aria-label="builder-step-description"
        value={value.label}
        onChange={handleTextChange()}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: 'unset !important',
          },
          '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline:hover': {
            border: 'none !important',
            outline: 'none',
          },
        }}
        inputProps={{
          readOnly: !isEditMode,
          sx: {
            lineHeight: '150%',
            '&:disabled': {
              color: 'initial',
            },
            '&::placeholder': { fontStyle: 'italic' },
            padding: 'unset !important',
          },
        }}
        placeholder={`Option ${index + 1}`}
        fullWidth
        multiline
      />
      {isEditMode && (
        <IconButton onClick={onDelete} size="small">
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
};
