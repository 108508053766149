import React, { FC } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import { WuiFormikTextField } from '@uiKit';
import { Formik, Form, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { FormikToggle } from 'components/Input/Field';
import { FormikObserver } from 'utils/formik';
import { FormBuilderSettingsTabsWrapper } from 'components/DocumentBuilder/FormBuilderSettingsTabsWrapper';
import { BuilderStepInputTextUI, StepListItem } from 'components/DocumentBuilder/types';
import { BuilderStepInputSettingsFormValuesCustomVariable, BuilderStepInputSettingsProps } from '../types';
import {
  BuilderStepSettingsCustomVariable,
  getCustomVariableSchema,
  handleFormReSlugifyOnChange,
} from '../components/BuilderStepSettingsCustomVariable';

const validationSchema = (stepId: string, steps: StepListItem[]) =>
  Yup.object({
    maxCharacters: Yup.number()
      .when('openMaxCharacters', {
        is: true,
        then: (schema) => schema.required('form-validation.number'),
      })
      .min(1),
    openMaxCharacters: Yup.boolean(),
    required: Yup.boolean(),
  }).concat(getCustomVariableSchema(stepId, steps));

interface InputTextConfigFormValues extends BuilderStepInputSettingsFormValuesCustomVariable {
  maxCharacters: number | null;
  openMaxCharacters: boolean;
  required: boolean;
}

export const BuilderStepInputTextSettings: FC<BuilderStepInputSettingsProps<BuilderStepInputTextUI>> = ({
  value,
  steps,
  onChange,
}) => {
  const intl = useIntl();

  const handleChange = (formik: FormikProps<InputTextConfigFormValues>) => (values: InputTextConfigFormValues) => {
    if (!values.openMaxCharacters && values.maxCharacters !== null) {
      // TODO: Formik doesn't like null for input values and drops an error, find another way to handle maxCharacter error
      formik.setFieldValue('maxCharacters', null);
    }
    if (values.openMaxCharacters && values.maxCharacters === null) {
      formik.setFieldValue('maxCharacters', 30);
    }

    const newListItem = {
      ...value,
      step: {
        ...value.step,
        maxCharacters: values.maxCharacters,
        required: values.required,
        isCustomVariableName: values.isCustomVariableName,
        variableName: values.variableName,
      },
    };

    onChange(
      newListItem,
      handleFormReSlugifyOnChange({
        listItem: value,
        newFormValue: values,
        formik,
      }),
    );
  };

  return (
    <Formik<InputTextConfigFormValues>
      initialValues={{
        maxCharacters: value.step.maxCharacters,
        openMaxCharacters: !!value.step.maxCharacters,
        required: value.step.required,
        variableName: value.step.variableName,
        isCustomVariableName: value.step.isCustomVariableName,
      }}
      validationSchema={() => validationSchema(value.step._id, steps)}
      onSubmit={() => {}}
      validateOnChange
      validateOnMount
      initialTouched={{
        variableName: true,
        maxCharacters: true,
      }}
    >
      {(formik) => (
        <FormBuilderSettingsTabsWrapper
          header={
            <Typography variant="h6">{intl.formatMessage({ id: 'document.builder.input-text.settings' })}</Typography>
          }
          general={
            <Stack spacing={1} component={Form} id="note-form">
              <FormikObserver<InputTextConfigFormValues> onChange={handleChange(formik)} />
              <FormikToggle name="openMaxCharacters" label="form.string.max-characters" />
              {formik.values.openMaxCharacters && (
                <WuiFormikTextField
                  required
                  variant="outlined"
                  type="number"
                  name="maxCharacters"
                  label="form.string.maximum-characters"
                  fullWidth
                  InputProps={{ inputProps: { min: 1 } }}
                />
              )}
              <Divider />
              <FormikToggle name="required" label="form-validation.required" />
              <Divider />
              <BuilderStepSettingsCustomVariable />
            </Stack>
          }
        />
      )}
    </Formik>
  );
};
