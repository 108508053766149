import { StepListItem } from 'components/DocumentBuilder/types';
import * as Yup from 'yup';

export const forbiddenCharactersRegex = /^[^@.$:? ]+$/;

export const getCustomVariableSchema = (currentStepId: string, listItems: StepListItem[]) =>
  Yup.object({
    isCustomVariableName: Yup.boolean(),
    variableName: Yup.string().when('isCustomVariableName', {
      is: true,
      then: (schema) => {
        const variableNamesWithoutCurrent = listItems
          .filter((item) => item.step._id !== currentStepId)
          .map((item) => item.step.variableName);

        const isVariableNameUnique = (value: string) => !variableNamesWithoutCurrent.includes(value);

        return schema
          .required('form-validation.required')
          .test('is-unique', 'form-validation.custom-variable.unique', isVariableNameUnique)
          .matches(forbiddenCharactersRegex, 'form-validation.custom-variable.forbidden-characters');
      },
      otherwise: (schema) => schema.nullable(),
    }),
  });
