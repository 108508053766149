import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import type { IDocumentBuilderStepInputTextResult } from '@workerbase/domain/document';
import { BuilderStepInputTextUI, StepListItem } from 'components/DocumentBuilder/types';
import { StepTextFieldPlaceholder } from '../StepTextInputPlaceholder';

interface BuilderStepInputTextProps {
  value: StepListItem<BuilderStepInputTextUI>;
  result?: IDocumentBuilderStepInputTextResult;
}

export const BuilderStepInputText: FC<BuilderStepInputTextProps> = ({ value: { step }, result }) => {
  const intl = useIntl();
  return (
    <StepTextFieldPlaceholder hasResult={!!result}>
      {step?.maxCharacters
        ? intl.formatMessage({ id: 'document.builder.input-text.placeholder' }, { characters: step?.maxCharacters })
        : undefined}

      {result?.value || null}
    </StepTextFieldPlaceholder>
  );
};
