import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik, FormikProps } from 'formik';
import { Divider, FormHelperText, Stack, Typography } from '@mui/material';
import { WuiFormikTextField } from '@uiKit';
import { FormikObserver } from 'utils/formik';
import { FormikToggle } from 'components/Input/Field';
import { FormBuilderSettingsTabsWrapper } from 'components/DocumentBuilder/FormBuilderSettingsTabsWrapper';
import { BuilderStepInputChecklistUI } from 'components/DocumentBuilder/types';
import { IBuilderStepInputCheckListOption } from '@workerbase/domain/document';
import { InputChecklistSettingsFormValues } from './types';
import { FlaggingCriteriaForm } from './components/FlaggingCriteriaForm/FlaggingCriteriaForm';
import {
  DEFAULT_FLAGGING_CRITERIA,
  DEFAULT_FLAGGING_CRITERIA_CONDITION,
  DEFAULT_FLAGGING_CRITERIA_GROUP,
} from './components/FlaggingCriteriaForm';
import {
  BuilderStepSettingsCustomVariable,
  handleFormReSlugifyOnChange,
} from '../components/BuilderStepSettingsCustomVariable';
import { BuilderStepInputSettingsProps } from '../types';
import { validationSchema } from './inputChecklistSettings.validationSchema';

export const checklistSettingsFlaggingCriteriaOptionsMapper = (options: IBuilderStepInputCheckListOption[]) =>
  options
    .filter((option) => option.label)
    .map((option) => ({
      label: option.label,
      value: option._id ?? '',
    }));

export const BuilderStepInputChecklistSettings: FC<BuilderStepInputSettingsProps<BuilderStepInputChecklistUI>> = ({
  value,
  steps,
  onChange,
}) => {
  const intl = useIntl();

  const handleChange = useCallback(
    (formik: FormikProps<InputChecklistSettingsFormValues>) => (values: InputChecklistSettingsFormValues) => {
      if (!values.multiple && values.minSelect !== null) {
        formik.setFieldValue('minSelect', null);
      }
      if (values.multiple && values.minSelect === null) {
        formik.setFieldValue('minSelect', 1);
      }

      onChange(
        {
          ...value,
          step: {
            ...value.step,
            minSelect: values.minSelect,
            multiple: values.multiple,
            required: values.required,
            flaggingCriteria: values.flaggingCriteria || null,
            variableName: values.variableName,
            isCustomVariableName: values.isCustomVariableName,
          },
        },
        handleFormReSlugifyOnChange({
          listItem: value,
          newFormValue: values,
          formik,
        }),
      );
    },
    [onChange, value],
  );

  const flaggingCriteriaOptions = useMemo(
    () => checklistSettingsFlaggingCriteriaOptionsMapper(value.step.options),
    [value.step.options],
  );

  return (
    <Formik
      initialValues={{
        stepId: value.step._id,
        minSelect: value.step.minSelect,
        multiple: value.step.multiple,
        required: value.step.required,
        hasFlaggingCriteria: !!((value?.step.flaggingCriteria?.conditions || []).length > 0),
        flaggingCriteria: value && value?.step.flaggingCriteria,
        isCustomVariableName: value.step.isCustomVariableName,
        variableName: value.step.variableName,
      }}
      validationSchema={() => validationSchema(value.step._id, steps, flaggingCriteriaOptions)}
      onSubmit={() => {}}
      validateOnChange
      validateOnMount
      initialTouched={{
        variableName: true,
        minSelect: true,
        flaggingCriteria: true,
        displayCriteria: true,
      }}
    >
      {(formik) => (
        <FormBuilderSettingsTabsWrapper
          header={
            <Typography variant="h6">
              {intl.formatMessage({ id: 'document.builder.input-checklist.settings' })}
            </Typography>
          }
          general={
            <Stack spacing={1} component={Form} id="note-form">
              <FormikObserver onChange={handleChange(formik)} />
              <FormikToggle name="multiple" label="form.checklist.allow-multiple" />
              {formik.values.multiple && (
                <>
                  <WuiFormikTextField
                    required
                    variant="outlined"
                    type="number"
                    name="minSelect"
                    label="form.checklist.minimum-selection"
                    fullWidth
                  />
                  {!!value.step.minSelect && value.step.minSelect > value.step.options.length && (
                    <FormHelperText error>
                      {intl.formatMessage({ id: 'document.builder.input-checklist.minimum-selection-impossible' })}
                    </FormHelperText>
                  )}
                </>
              )}
              <Divider />
              <FormikToggle name="required" label="form-validation.required" />
              <Divider />
              <BuilderStepSettingsCustomVariable />
            </Stack>
          }
          flaggingCriteria={
            <>
              <FormikToggle
                name="hasFlaggingCriteria"
                label={intl.formatMessage({ id: 'document.set-flagging-criteria' })}
                labelIsTranslationId={false}
                onToggleCallback={(hasFlaggingCriteria: boolean) => {
                  formik.setFieldValue(
                    'flaggingCriteria',
                    hasFlaggingCriteria
                      ? {
                          ...DEFAULT_FLAGGING_CRITERIA,
                          conditions: [
                            {
                              ...DEFAULT_FLAGGING_CRITERIA_GROUP,
                              conditions: [
                                {
                                  ...DEFAULT_FLAGGING_CRITERIA_CONDITION,
                                  name: flaggingCriteriaOptions.length > 0 ? flaggingCriteriaOptions[0].value : '',
                                },
                              ],
                            },
                          ],
                        }
                      : null,
                  );
                }}
              />

              {formik.values.hasFlaggingCriteria && (
                <FlaggingCriteriaForm name="flaggingCriteria" options={flaggingCriteriaOptions} />
              )}
            </>
          }
        />
      )}
    </Formik>
  );
};
