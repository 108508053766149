import { EmailLoginResponse } from '@workerbase/api/http/auth';

export const mapSSOQueryParamsToUser = (query: URLSearchParams): EmailLoginResponse => ({
  isAiEnabled: Boolean(query.get('isAiEnabled')),
  isConnectedWorkerEnabled: query.get('isConnectedWorkerEnabled') === 'true',
  isGDriveEnabled: Boolean(query.get('isGDriveEnabled')),
  isHideProfilePictureEnabled: Boolean(query.get('isHideProfilePictureEnabled')),
  isMyWorkEnabled: query.get('isMyWorkEnabled') === 'true',
  isOnPremise: query.get('isOnPremise') === 'true',
  isServerlessEnabled: query.get('isServerlessEnabled') === 'true',
  isWorkbenchEnabled: query.get('isWorkbenchEnabled') === 'true',
  loginToken: query.get('loginToken') ?? '',
  refreshToken: query.get('refreshToken') ?? '',
  tokenTTLInMs: Number.parseInt(query.get('tokenTTLInMs') ?? '0', 10),
  updatePIN: Boolean(query.get('updatePIN')),
  user: JSON.parse(query.get('user') ?? '{}'),
  speedUpEnabled: Boolean(query.get('speedUpEnabled')),
  speedUpInitialTaskPrompt: query.get('speedUpInitialTaskPrompt') || '',
});
