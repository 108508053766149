import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import PageLoader from 'components/PageLoader';
import LoggedInRoute from 'components/LoggedInRoute';
import LoggedOutRoute from 'components/LoggedOutRoute';
import { LandingSSO } from 'components/LandingSSO';
import { AccessRole } from 'utils/accessRole';

const Workbench = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "workbench" */ './pages/Workbench'));

const Login = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "login" */ './pages/Login'));
const Logout = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "logout" */ './pages/Logout'));
const ResetPassword = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "resetpassword" */ './pages/ResetPassword'),
);
const ResetPasswordNewPassword = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "resetpasswordnew" */ './pages/ResetPasswordNewPassword'),
);

// DEVICES
const DevicesList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "devicesList" */ './pages/Devices/DevicesList'),
);

// USERS
const UsersList = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "users" */ './pages/Users/UsersList'));
const UserEdit = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "users" */ './pages/Users/UserEdit'));
const UserCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "users" */ './pages/Users/UserCreate'),
);
const UserView = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "users" */ './pages/Users/UserView').then((module) => ({
    default: module.UserView,
  })),
);
const Profile = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "profile" */ './pages/Users/Profile').then((module) => ({
    default: module.Profile,
  })),
);
const ProfileEdit = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "profile" */ './pages/Users/ProfileEdit').then((module) => ({
    default: module.ProfileEdit,
  })),
);

// ROLES
const RolesList = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "roles" */ './pages/Roles/RolesList'));
const RoleEdit = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "roles" */ './pages/Roles/RoleEdit'));
const RoleCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "roles" */ './pages/Roles/RoleCreate'),
);

// SKILLS
const SkillsList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "skills" */ './pages/Skills/SkillsList'),
);
const SkillEdit = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "skills" */ './pages/Skills/SkillEdit'),
);
const SkillCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "skills" */ './pages/Skills/SkillCreate'),
);

// PROJECTS
const ProjectsList = lazy(
  () => import(/* webpackPreload: true, webpackChunkName: "projects" */ './pages/Projects/ProjectsList'),
);
const ProjectCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "projects" */ './pages/Projects/ProjectCreate'),
);
const ProjectSettings = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "projects" */ './pages/Projects/ProjectSettings'),
);
const ProjectDashboard = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "projects" */ './pages/Projects/ProjectDashboard'),
);
const ProjectLogs = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "projects" */ './pages/Projects/ProjectLogs'),
);
// PROJECTS - TASKS
const ProjectTasksList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "projects" */ './pages/Projects/ProjectTasksList'),
);
const ProjectTaskCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "connectors" */ './pages/Projects/ProjectTaskCreate'),
);
// PROJECTS - WORKINSTRUCTIONS
const ProjectWorkinstructionCreate = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "workinstructions" */ './pages/Projects/ProjectWorkinstructionCreate'
    ),
);
const ProjectWorkinstructionsList = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "workinstructions" */ './pages/Projects/ProjectWorkinstructionsList'
    ),
);
// PROJECTS - RULES
const ProjectRulesList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "rules" */ './pages/Projects/ProjectRulesList'),
);
const ProjectRuleCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "rules" */ './pages/Projects/ProjectRuleCreate'),
);
// PROJECTS - CONNECTORS
const ProjectConnectorsList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "connectors" */ './pages/Projects/ProjectConnectorsList'),
);
const ProjectConnectorCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "connectors" */ './pages/Projects/ProjectConnectorCreate'),
);

// PROJECTS - FUNCTIONS
const ProjectFunctionsList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "functions" */ './pages/Projects/ProjectFunctionsList'),
);
const ProjectFunctionsCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "functions" */ './pages/Projects/ProjectFunctionCreate'),
);
const ProjectFunctionsEdit = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "functions" */ './pages/Projects/ProjectFunctionEdit'),
);

// DATABASES
const DatabasesList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabasesList'),
);
const DatabaseItemsList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabaseItemsList'),
);
const DatabaseCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabaseCreate'),
);
const DatabaseSettings = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabaseSettings'),
);
const DatabaseItemCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabaseItemCreate'),
);
const DatabaseItemEdit = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabaseItemEdit'),
);
const DatabaseViewItemsList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabaseViewItemsList'),
);

// DOCUMENTS
const ProjectWorkinstructionGenerate = lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "documents" */ './pages/Projects/ProjectWorkinstructionGenerate'
  ).then((module) => ({
    default: module.ProjectWorkinstructionGenerate,
  })),
);

const DocumentsPage = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "documents" */ './pages/Documents/DocumentsPage').then(
    (module) => ({
      default: module.DocumentsPage,
    }),
  ),
);

const DocumentsUploadFilesPage = lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "documents" */ './pages/Documents/UploadFilesPage/UploadFilesPage'
  ).then((module) => ({
    default: module.UploadFilesPage,
  })),
);
const DocumentPage = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "documents" */ './pages/Documents/DocumentPage/DocumentPage').then(
    (module) => ({
      default: module.DocumentPage,
    }),
  ),
);

// LOCATIONS
const LocationsList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "locations" */ './pages/Locations/LocationsList'),
);
const LocationCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "locations" */ './pages/Locations/LocationCreate'),
);
const LocationEdit = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "locations" */ './pages/Locations/LocationEdit'),
);

// CONNECTORS
const ConnectorEdit = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "connectors" */ './pages/Connectors/ConnectorEdit'),
);

// WORKINSTRUCTIONS
const WorkinstructionEdit = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "workinstructions" */ './pages/Workinstructions/WorkinstructionEdit'
    ),
);

// RULES
const RuleEdit = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "rules" */ './pages/Rules/RuleEdit'));

const Settings = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "settings" */ './pages/Settings'));

const EditShiftPlanForm = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "settings" */ './pages/Settings/ShiftPlans/ShiftPlanEdit'),
);

const CreateShiftPlanForm = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "settings" */ './pages/Settings/ShiftPlans/ShiftPlanCreate'),
);

const ModulesCreate = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "settings" */ './pages/Settings/Modules/ModulesCreate').then(
    (module) => ({ default: module.ModulesCreate }),
  ),
);

// FIND
const FindPage = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "find" */ './pages/Find/FindPage').then((module) => ({
    default: module.FindPage,
  })),
);
const FindSearchResultsPage = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "find" */ './pages/Find/FindSearchResultsPage').then((module) => ({
    default: module.FindSearchResultsPage,
  })),
);

const FindResourceTypePage = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "find" */ './pages/Find/FindByResourceTypePage').then(
    (module) => ({
      default: module.FindByResourceTypePage,
    }),
  ),
);

const FindDetailsPage = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "find" */ './pages/Find/FindDetailsPage').then((module) => ({
    default: module.FindDetailsPage,
  })),
);

const FindFormResultPage = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "find" */ './pages/Find/FindFormResultPage').then((module) => ({
    default: module.FindFormResultPage,
  })),
);

const Chat = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "chat" */ './pages/Chat/Chat').then((module) => ({
    default: module.Chat,
  })),
);

const NotFound = lazy(() => import('./pages/NotFound'));

export enum Routes {
  Root = '/',
  Login = '/login',
  LandingSSO = '/landing-sso',
  ResetPasswordRequest = '/reset-password',
  ResetPassword = '/reset-password/:resetToken',
  Logout = '/logout',
  Users = '/users',
  UserCreate = '/users/create', // admin-only route
  UserView = '/users/:userId', // admin-only route
  UserEdit = '/users/:userId/edit', // admin-only route
  Profile = '/profile/:userId',
  ProfileEdit = '/profile/:userId/edit',
  Roles = '/roles',
  Chat = '/chat',
  RoleCreate = '/roles/create',
  RoleEdit = '/roles/:roleId/edit',
  Skills = '/skills',
  SkillCreate = '/skills/create',
  SkillEdit = '/skills/:skillId/edit',
  Projects = '/projects',
  ProjectCreate = '/projects/create',
  Project = '/projects/:projectId',
  ProjectSettings = '/projects/:projectId/settings',
  ProjectEdit = '/projects/:projectId/edit',
  ProjectDashboard = '/projects/:projectId/dashboard',
  ProjectTasks = '/projects/:projectId/tasks',
  ProjectTaskCreate = '/projects/:projectId/tasks/create',
  ProjectRules = '/projects/:projectId/rules',
  ProjectRuleCreate = '/projects/:projectId/rules/create',
  ProjectRuleEdit = '/projects/:projectId/rules/:ruleId/edit',
  ProjectWorkinstructions = '/projects/:projectId/workinstructions',
  ProjectWorkinstructionCreate = '/projects/:projectId/workinstructions/create',
  ProjectWorkinstructionGenerate = '/projects/:projectId/workinstructions/generate',
  ProjectWorkinstructionEdit = '/projects/:projectId/workinstructions/:workinstructionId/edit',
  ProjectConnectors = '/projects/:projectId/connectors',
  ProjectConnectorCreate = '/projects/:projectId/connectors/create',
  ProjectConnectorEdit = '/projects/:projectId/connectors/:connectorId/edit',
  ProjectFunctions = '/projects/:projectId/functions',
  ProjectFunctionCreate = '/projects/:projectId/functions/create',
  ProjectFunctionEdit = '/projects/:projectId/functions/:functionId/edit',
  ProjectLogs = '/projects/:projectId/logs',
  Devices = '/devices',
  Locations = '/locations',
  LocationCreate = '/locations/create',
  LocationEdit = '/locations/:locationId/edit',
  Databases = '/databases',
  DatabaseCreate = '/databases/create',
  DatabaseSettings = '/databases/:databaseIdOrSlug/settings',
  DatabaseItemsImport = '/databases/:databaseIdOrSlug/settings/import',
  DatabaseItems = '/databases/:databaseIdOrSlug',
  DatabaseItemCreate = '/databases/:databaseIdOrSlug/items/create',
  DatabaseItemEdit = '/databases/:databaseIdOrSlug/items/:itemId/edit',
  DatabaseViews = '/databases/:databaseIdOrSlug/views',
  DatabaseViewItems = '/databases/:databaseIdOrSlug/views/:viewId',
  Documents = '/documents',
  DocumentsUploadFiles = '/documents/new-files',
  Document = '/documents/:documentId',
  Workbench = '/workbench',
  Settings = '/settings/:currentTab',
  SettingsNetworkSetup = '/settings/network-setup',
  SettingsShiftPlanCreate = '/settings/shift-plans/create',
  SettingsShiftPlanEdit = '/settings/shift-plans/:shiftPlanId/edit',
  SettingsModulesCreate = '/settings/modules/create',
  Find = '/find',
  FindSearchResults = '/find/search',
  FindResourceType = '/find/:resourceType',
  FindDetails = '/find/:resourceType/:resourceId',
  FindFormResultPage = '/find/:resourceType/:resourceId/:formId/:formResultId',
}

const routes = () => (
  <Suspense fallback={<PageLoader />}>
    <Switch>
      <LoggedOutRoute path={Routes.LandingSSO} exact component={LandingSSO} />
      <LoggedOutRoute path={Routes.Login} exact component={Login} />
      <LoggedOutRoute path={Routes.ResetPasswordRequest} exact component={ResetPassword} />
      <LoggedOutRoute path={Routes.ResetPassword} exact component={ResetPasswordNewPassword} />
      <LoggedInRoute path={Routes.Logout} exact component={Logout} />
      <LoggedInRoute path={Routes.Users} exact component={UsersList} />
      <LoggedInRoute
        path={Routes.UserCreate}
        exact
        component={UserCreate}
        accessRoles={[AccessRole.ADMIN]}
        accessDeniedRedirectPath={Routes.Users}
      />
      <LoggedInRoute path={Routes.UserView} exact component={UserView} />
      <LoggedInRoute path={Routes.UserEdit} exact component={UserEdit} />
      <LoggedInRoute path={Routes.Profile} exact component={Profile} />
      <LoggedInRoute path={Routes.ProfileEdit} exact component={ProfileEdit} />
      <LoggedInRoute path={Routes.Roles} exact component={RolesList} />
      <LoggedInRoute path={Routes.Chat} exact component={Chat} />
      <LoggedInRoute
        path={Routes.RoleCreate}
        exact
        component={RoleCreate}
        accessRoles={[AccessRole.ADMIN]}
        accessDeniedRedirectPath={Routes.Roles}
      />
      <LoggedInRoute
        path={Routes.RoleEdit}
        exact
        component={RoleEdit}
        accessRoles={[AccessRole.ADMIN]}
        accessDeniedRedirectPath={Routes.Roles}
      />
      <LoggedInRoute path={Routes.Skills} exact component={SkillsList} />
      <LoggedInRoute
        path={Routes.SkillCreate}
        exact
        component={SkillCreate}
        accessRoles={[AccessRole.ADMIN]}
        accessDeniedRedirectPath={Routes.Skills}
      />
      <LoggedInRoute
        path={Routes.SkillEdit}
        exact
        component={SkillEdit}
        accessRoles={[AccessRole.ADMIN]}
        accessDeniedRedirectPath={Routes.Skills}
      />
      <LoggedInRoute path={Routes.Projects} exact component={ProjectsList} />
      <Redirect from={Routes.ProjectDashboard} to={Routes.Project} />
      <LoggedInRoute
        path={Routes.ProjectCreate}
        exact
        component={ProjectCreate}
        accessRoles={[AccessRole.ADMIN]}
        accessDeniedRedirectPath={Routes.Projects}
      />
      <LoggedInRoute path={Routes.Project} exact component={ProjectDashboard} />
      <LoggedInRoute path={Routes.ProjectSettings} exact component={ProjectSettings} />
      <LoggedInRoute path={Routes.ProjectLogs} exact component={ProjectLogs} />
      <LoggedInRoute path={Routes.ProjectTasks} exact component={ProjectTasksList} />
      <LoggedInRoute path={Routes.ProjectTaskCreate} exact component={ProjectTaskCreate} />
      <LoggedInRoute path={Routes.ProjectRules} exact component={ProjectRulesList} />
      <LoggedInRoute path={Routes.ProjectRuleCreate} exact component={ProjectRuleCreate} />
      <LoggedInRoute path={Routes.ProjectRuleEdit} exact component={RuleEdit} />
      <LoggedInRoute path={Routes.ProjectWorkinstructions} exact component={ProjectWorkinstructionsList} />
      <LoggedInRoute path={Routes.ProjectWorkinstructionCreate} exact component={ProjectWorkinstructionCreate} />
      <LoggedInRoute path={Routes.ProjectWorkinstructionGenerate} exact component={ProjectWorkinstructionGenerate} />
      <LoggedInRoute path={Routes.ProjectWorkinstructionEdit} exact component={WorkinstructionEdit} />
      <LoggedInRoute path={Routes.ProjectConnectors} exact component={ProjectConnectorsList} />
      <LoggedInRoute path={Routes.ProjectConnectorCreate} exact component={ProjectConnectorCreate} />
      <LoggedInRoute path={Routes.ProjectConnectorEdit} exact component={ConnectorEdit} />
      <LoggedInRoute path={Routes.ProjectFunctions} exact component={ProjectFunctionsList} />
      <LoggedInRoute path={Routes.ProjectFunctionCreate} exact component={ProjectFunctionsCreate} />
      <LoggedInRoute path={Routes.ProjectFunctionEdit} exact component={ProjectFunctionsEdit} />
      <LoggedInRoute path={Routes.Devices} exact component={DevicesList} />
      <LoggedInRoute path={Routes.Locations} exact component={LocationsList} />
      <LoggedInRoute path={Routes.FindFormResultPage} exact component={FindFormResultPage} />
      <LoggedInRoute
        path={Routes.LocationCreate}
        exact
        component={LocationCreate}
        accessRoles={[AccessRole.ADMIN]}
        accessDeniedRedirectPath={Routes.Locations}
      />
      <LoggedInRoute
        path={Routes.LocationEdit}
        exact
        component={LocationEdit}
        accessRoles={[AccessRole.ADMIN]}
        accessDeniedRedirectPath={Routes.Locations}
      />
      <LoggedInRoute path={Routes.Databases} exact component={DatabasesList} />
      <LoggedInRoute
        path={Routes.DatabaseCreate}
        exact
        component={DatabaseCreate}
        accessRoles={[AccessRole.ADMIN]}
        accessDeniedRedirectPath={Routes.Databases}
      />
      <LoggedInRoute path={Routes.DatabaseItems} exact component={DatabaseItemsList} />
      <LoggedInRoute path={Routes.DatabaseItemCreate} exact component={DatabaseItemCreate} />
      <LoggedInRoute path={Routes.DatabaseItemEdit} exact component={DatabaseItemEdit} />
      <LoggedInRoute path={Routes.DatabaseSettings} exact component={DatabaseSettings} />
      <LoggedInRoute path={Routes.DatabaseViews} exact component={DatabaseItemsList} />
      <LoggedInRoute path={Routes.DatabaseViewItems} exact component={DatabaseViewItemsList} />
      <LoggedInRoute path={Routes.Documents} exact component={DocumentsPage} />
      <LoggedInRoute path={Routes.DocumentsUploadFiles} exact component={DocumentsUploadFilesPage} />
      <LoggedInRoute path={Routes.Document} exact component={DocumentPage} />
      <LoggedInRoute path={Routes.Find} exact component={FindPage} />
      <LoggedInRoute path={Routes.FindSearchResults} exact component={FindSearchResultsPage} />
      <LoggedInRoute path={Routes.FindResourceType} exact component={FindResourceTypePage} />
      <LoggedInRoute path={Routes.FindDetails} exact component={FindDetailsPage} />
      <LoggedInRoute path={Routes.Workbench} exact component={Workbench} />
      <LoggedInRoute path={Routes.Settings} exact component={Settings} />
      <LoggedInRoute
        shouldBeRootAdministrator
        ifNotRootAdminRedirectTo={Routes.SettingsNetworkSetup}
        path={Routes.SettingsShiftPlanEdit}
        exact
        component={EditShiftPlanForm}
        accessRoles={[AccessRole.ADMIN]}
        accessDeniedRedirectPath={Routes.SettingsNetworkSetup}
      />
      <LoggedInRoute
        path={Routes.SettingsShiftPlanCreate}
        exact
        component={CreateShiftPlanForm}
        accessRoles={[AccessRole.ADMIN]}
        accessDeniedRedirectPath={Routes.SettingsNetworkSetup}
      />
      <LoggedInRoute
        path={Routes.SettingsModulesCreate}
        exact
        component={ModulesCreate}
        accessRoles={[AccessRole.ADMIN, AccessRole.DEVELOPER]}
      />
      <LoggedInRoute
        path={Routes.SettingsModulesCreate}
        exact
        component={ModulesCreate}
        accessRoles={[AccessRole.ADMIN, AccessRole.DEVELOPER]}
      />
      <Redirect from={Routes.Root} exact to={Routes.Find} />
      <Route path="*" component={NotFound} />
    </Switch>
  </Suspense>
);

export default routes;
