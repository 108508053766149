import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import type {
  IBuilderStepInputCheckListOption,
  IDocumentBuilderStepInputChecklistResult,
} from '@workerbase/domain/document';
import { WuiActionButton } from '@uiKit/WuiButtons/WuiActionButton';
import { generateUniqueSlug } from 'components/DocumentBuilder/utils';
import { BuilderStepInputChecklistUI, StepListItem } from 'components/DocumentBuilder/types';
import generateRandomId from 'utils/generateRandomId';
import { BuilderStepInputChecklistOption } from './BuilderStepInputChecklistOption';

interface BuilderStepInputChecklistProps {
  value: StepListItem<BuilderStepInputChecklistUI>;
  result?: IDocumentBuilderStepInputChecklistResult;
  isSelected: boolean;
  onChange: (value: StepListItem<BuilderStepInputChecklistUI>) => void;
}

export const BuilderStepInputChecklist: FC<BuilderStepInputChecklistProps> = ({
  value,
  result,
  onChange,
  isSelected,
}) => {
  const intl = useIntl();

  const handleDelete = (index: number) => () => {
    const newOptions = [...value.step.options];
    newOptions.splice(index, 1);
    onChange({ ...value, step: { ...value.step, options: newOptions } });
  };

  const handleChange = (index: number) => (option: IBuilderStepInputCheckListOption) => {
    const newOptions = [...value.step.options];

    const slug = generateUniqueSlug({
      name: option.label || `${intl.formatMessage({ id: 'global.option' })}${index + 1}`,
      existingSlugs: value.step.options.map((op) => op.value),
    });

    const newOption = {
      label: option.label,
      value: slug,
      _id: option._id,
    };

    newOptions.splice(index, 1, newOption);
    onChange({ ...value, step: { ...value.step, options: newOptions } });
  };

  const addOption = () => {
    onChange({
      ...value,
      step: {
        ...value.step,
        options: [
          ...value.step.options,
          {
            value: '',
            label: '',
            _id: generateRandomId(),
          },
        ],
      },
    });
  };

  const options = React.useMemo(
    () => value.step.options.map((item, index) => ({ ...item, id: index })),
    [value.step.options],
  );

  return (
    <Box>
      {options.map((option, index) => (
        <BuilderStepInputChecklistOption
          key={option.id}
          index={index}
          value={option}
          isChecked={!!result?.value?.some((element) => element.value === option.value)}
          multiple={value.step.multiple}
          isEditMode={isSelected}
          onChange={handleChange(index)}
          onDelete={handleDelete(index)}
        />
      ))}
      {isSelected && (
        <WuiActionButton sx={{ mt: 1 }} onClick={addOption}>
          {intl.formatMessage({ id: 'global.add-option' })}
        </WuiActionButton>
      )}
    </Box>
  );
};
