import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Close } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { WuiFormikSelect, WuiFormikTextField } from '@uiKit';
import { InputNumberOperators } from '@workerbase/domain/document';
import { getOperatorTranslationId } from 'utils/mapping';

interface NumberConditionProps {
  name: string;
  onDelete?: (index) => void;
}

export const NumberCondition: FC<NumberConditionProps> = ({ name, onDelete }) => {
  const intl = useIntl();

  return (
    <Box
      display="flex"
      gap={1.5}
      alignItems="center"
      sx={{
        '> div ': {
          width: '100%',
        },
      }}
    >
      <Stack gap={1} direction="row">
        <WuiFormikSelect
          fullWidth
          name={`${name}.operator`}
          options={Object.values(InputNumberOperators).map((item) => ({
            label: getOperatorTranslationId(item) ? intl.formatMessage({ id: getOperatorTranslationId(item) }) : item,
            value: item,
          }))}
        />
        <WuiFormikTextField name={`${name}.value`} />

        {onDelete && (
          <IconButton onClick={onDelete}>
            <Close />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
};
