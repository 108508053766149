import React, { FC } from 'react';
import { BuilderStepInfoTextUI, StepListItem } from 'components/DocumentBuilder/types';
import { WuiRichTextInput } from '../../../uiKit/WuiRichTextInput/WuiRichTextInput';

interface BuilderStepInfoTextProps {
  value: StepListItem<BuilderStepInfoTextUI>;
  isPreviewMode?: boolean;
  isSelected?: boolean;
  onChange: (value: StepListItem<BuilderStepInfoTextUI>) => void;
}

export const BuilderStepInfoTextInner: FC<BuilderStepInfoTextProps> = ({
  value: stepValue,
  isPreviewMode,
  isSelected,
  onChange,
}) => (
  <WuiRichTextInput
    value={stepValue.step.description || ''}
    isReadOnlyMode={isPreviewMode}
    isToolbarShown={isSelected}
    onChange={(stringValue) => onChange({ ...stepValue, step: { ...stepValue.step, description: stringValue } })}
  />
);

export const BuilderStepInfoText = React.memo(
  BuilderStepInfoTextInner,
  (prevProps, nextProps) =>
    prevProps.value.step.description === nextProps.value.step.description &&
    prevProps.isPreviewMode === nextProps.isPreviewMode &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.value.step.title === nextProps.value.step.title,
);
