// https://stackoverflow.com/a/19605207/651299
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/;
export const URL_REGEX =
  // eslint-disable-next-line max-len  -- regex
  /^(?!mailto:)(?:(?:http|https|ftp):\/\/)(?:[\w-]+\.{1}(?:[a-zA-Z]{2,63})|([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]))(?:[/\w-]*)*\/?\??(?:[^#\n\r]*)?#?(?:[^\n\r]*)$/i;
export const VAR_REGEX = /^\$\w+$/;
export const OBJECTID_URL_REGEX = /^(?:http(s)?:\/\/)([a-f0-9\d]{24})(:\d*)?(\/.*)*$/i;

export const NA = 'N/A';

export const BACKEND_HOSTNAME =
  process.env.NODE_ENV === 'production' || GARDEN_ENV ? window.location.hostname : DOMAIN || 'develop.workerbase.net';

export const BASE_URL = process.env.NODE_ENV === 'production' ? '' : `https://${BACKEND_HOSTNAME}`;

export const BACKEND_PORT = 443;

export const mqttConfig =
  process.env.NODE_ENV === 'production'
    ? {
        host: BACKEND_HOSTNAME,
        protocol: window.location.protocol === 'http:' ? 'ws' : 'wss',
        port: Number.parseInt(window.location.port, 10) || (window.location.protocol === 'https:' ? 443 : 80),
      }
    : {
        host: BACKEND_HOSTNAME,
        protocol: 'wss',
        port: BACKEND_PORT,
      };

export const OLD_FRONTEND_COOKIE_NAME = 'activate_old_frontend';

export const PROJECT_SERVICE_HOSTNAME = `http://projects:3000`;

export const GDRIVE_CLIENT_ID =
  process.env.GDRIVE_CLIENT_ID || '42041887130-jld05nfdj3j8k34u29ho2oor632nipgs.apps.googleusercontent.com';
export const GDRIVE_DEVELOPER_KEY = process.env.GDRIVE_DEVELOPER_KEY || 'AIzaSyA92M73Wj_uAZ3YSdmkOTmYa93qNGgBWIQ';

export const IS_DEV_MODE =
  String(DEV_MODE) === 'true' || ['staging', 'review'].some((substring) => window.location.href.includes(substring));
