import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Close } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { WuiFormikSelect } from '@uiKit';
import { IFilterCondition } from '@workerbase/domain/common';
import { IBuilderStepInputCheckListOption, InputChecklistOperators } from '@workerbase/domain/document';

export interface ChecklistConditionProps {
  name: string;
  options: IBuilderStepInputCheckListOption[];
  /** @default "name" */
  conditionFieldKey?: keyof IFilterCondition;
  onDelete?: () => void;
}

export const ChecklistCondition: FC<ChecklistConditionProps> = ({
  name,
  options,
  conditionFieldKey = 'name',
  onDelete,
}) => {
  const intl = useIntl();

  return (
    <Box
      display="flex"
      gap={1}
      alignItems="center"
      sx={{
        '> div ': {
          width: '100%',
        },
      }}
    >
      <Stack gap={1.5}>
        <WuiFormikSelect
          name={`${name}.${conditionFieldKey}`}
          options={options}
          showUntouchedError
          disabled={options.length === 0}
        />

        <WuiFormikSelect
          name={`${name}.operator`}
          options={Object.values(InputChecklistOperators).map((item) => ({
            label: intl.formatMessage({ id: `document.builder.input-step-settings.${item}` }),
            value: item,
          }))}
        />
      </Stack>

      {onDelete && (
        <IconButton onClick={onDelete}>
          <Close color="action" />
        </IconButton>
      )}
    </Box>
  );
};
