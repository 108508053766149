import { RoleResponse } from '@workerbase/api/http/role';
import { SkillResponse } from '@workerbase/api/http/skill';
import { PinCredentialsResponse } from '@workerbase/api/http/user';
import { Languages } from '@workerbase/domain/common';
import { UserUsageTypes } from '@workerbase/domain/user';

export enum UsersCategories {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface LoggedUser {
  id: string;
  firstName: string;
  lastName: string;
  language: Languages;
  email: string;
  isOnPremise: boolean;
  isRootAdministrator: boolean;
  isDeveloper: boolean;
  disableDeviceLock: boolean;
  isWorkbenchEnabled: boolean;
  isMyWorkEnabled: boolean;
  isAiEnabled?: boolean;
  speedUpEnabled: boolean;
  speedUpInitialTaskPrompt: string;
  isGDriveEnabled?: boolean;
  isHideProfilePictureEnabled?: boolean;
  roleIds: string[];
}

// TODO: REfactor to  API Package
export interface User {
  id: string;
  firstName: string;
  lastName: string;
  active: boolean;
  language: Languages;
  department: string;
  email: string;
  phoneNumber: string;
  roles?: RoleResponse[]; // will be undefined in getUser response
  rolesIds: string[];
  skills?: SkillResponse[]; // will be undefined in getUser response
  skillsIds: string[];
  shiftPlanId: string;
  disableDeviceLock: boolean;
  isRootAdministrator: boolean;
  isDeviceOnlyUser: boolean;
  isDeveloper: boolean;
  locationName?: string;
  profilePicture?: string;
  usageType: UserUsageTypes;
  pinCredentials: Partial<PinCredentialsResponse>;
}
