import { ComparingConditionOperator, ConditionOperator, DirectConditionOperator } from '@workerbase/domain/rule';

export const OPERATORS_DISPLAYING_ONLY_SYMBOL = [
  ComparingConditionOperator.GREATER_THAN,
  ComparingConditionOperator.LESS_THAN,
  ComparingConditionOperator.GREATER_OR_EQUAL,
  ComparingConditionOperator.LESS_OR_EQUAL,
] as const;

type TranslatableOperator = Exclude<ConditionOperator, (typeof OPERATORS_DISPLAYING_ONLY_SYMBOL)[number]>;

export const OPERATORS_TRANSLATION_ID_MAPPING: Record<TranslatableOperator, string> = {
  [DirectConditionOperator.EMPTY]: 'rules.filter.empty',
  [ComparingConditionOperator.EQUAL]: 'rules.filter.equal',
  [DirectConditionOperator.IS_NOT_EMPTY]: 'rules.filter.is-not-empty',
  [ComparingConditionOperator.MATCHES]: 'rules.filter.matches',
  [ComparingConditionOperator.CONTAINS]: 'rules.filter.contains',
  [ComparingConditionOperator.BEGINS]: 'rules.filter.begins',
  [ComparingConditionOperator.ENDS]: 'rules.filter.ends',
  [DirectConditionOperator.EXISTS]: 'rules.filter.exists',
  [ComparingConditionOperator.EQUALS_VAR]: 'rules.filter.equals-variable',
  [ComparingConditionOperator.REGEX]: 'rules.filter.regex',
  [ComparingConditionOperator.DOES_NOT_EQUAL]: 'rules.filter.does-not-equal',
  [ComparingConditionOperator.DOES_NOT_MATCH]: 'rules.filter.does-not-match',
  [ComparingConditionOperator.DOES_NOT_CONTAIN]: 'rules.filter.does-not-contain',
  [ComparingConditionOperator.DOES_NOT_BEGIN]: 'rules.filter.does-not-begin',
  [ComparingConditionOperator.DOES_NOT_END]: 'rules.filter.does-not-end',
  [DirectConditionOperator.DOES_NOT_EXIST]: 'rules.filter.does-not-exist',
  [DirectConditionOperator.TRUE]: 'rules.filter.true',
  [DirectConditionOperator.FALSE]: 'rules.filter.false',
  [DirectConditionOperator.IS_CHECKED]: 'rules.filter.isChecked',
  [DirectConditionOperator.IS_NOT_CHECKED]: 'rules.filter.isNotChecked',
};

/**
 * Type guard to check if an operator has a translation mapping
 */
export const hasTranslation = (operator: ConditionOperator): operator is TranslatableOperator =>
  operator in OPERATORS_TRANSLATION_ID_MAPPING;

/**
 * Get translation ID for an operator, returns undefined if no translation exists
 */
export const getOperatorTranslationId = (operator: ConditionOperator): string | undefined =>
  hasTranslation(operator) ? OPERATORS_TRANSLATION_ID_MAPPING[operator] : undefined;
