import { Add } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { IFilterGroup } from '@workerbase/domain/common';
import { IBuilderStepInputCheckListOption, InputChecklistOperators } from '@workerbase/domain/document';
import { useFormikContext } from 'formik';
import { clone, get } from 'lodash';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { ChecklistCondition } from 'components/DocumentBuilder/components/ChecklistCondition';
import { DEFAULT_FLAGGING_CRITERIA_CONDITION } from './constants';

interface FlaggingCriteriaGroupProps {
  name: string;
  options: IBuilderStepInputCheckListOption[];
  onDelete: () => void;
}

export const FlaggingCriteriaGroup: FC<FlaggingCriteriaGroupProps> = ({ name, options, onDelete }) => {
  const intl = useIntl();
  const formik = useFormikContext();

  const flaggingCriteriaGroup: IFilterGroup<InputChecklistOperators> = get(formik.values, name);

  const handleAddCondition = () => {
    formik.setFieldValue(`${name}.conditions`, [
      ...flaggingCriteriaGroup.conditions,
      { ...DEFAULT_FLAGGING_CRITERIA_CONDITION, name: options[0].value },
    ]);
  };

  const handleDeleteCondition = (index: number) => {
    const groupConditions = clone(flaggingCriteriaGroup.conditions);
    groupConditions.splice(index, 1);

    if (groupConditions.length) {
      formik.setFieldValue(`${name}.conditions`, groupConditions);
    } else {
      onDelete();
    }
  };

  return (
    <Stack gap={1.5}>
      {flaggingCriteriaGroup.conditions?.map((_condition, index) => (
        <ChecklistCondition
          key={index}
          name={`${name}.conditions.${index}`}
          options={options}
          onDelete={() => handleDeleteCondition(index)}
        />
      ))}

      <Button
        variant="contained"
        color="inherit"
        size="small"
        sx={{
          padding: '4px, 10px, 4px, 10px',
          background: '#E0E0E0',
          width: 'fit-content',
        }}
        onClick={handleAddCondition}
      >
        <Add /> {intl.formatMessage({ id: 'document.builder.input-step-settings.and' })}
      </Button>
    </Stack>
  );
};
