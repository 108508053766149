import React, { FC } from 'react';
import type { IDocumentBuilderStepInputScancodeResult } from '@workerbase/domain/document';
import { Button, Stack } from '@mui/material';
import { QrCodeScanner as QrCodeScannerIcon } from '@mui/icons-material';
import { BuilderStepInputScancodeUI, StepListItem } from 'components/DocumentBuilder/types';
import { StepTextFieldPlaceholder } from '../StepTextInputPlaceholder';

interface BuilderStepInputScancodeProps {
  value: StepListItem<BuilderStepInputScancodeUI>;
  result?: IDocumentBuilderStepInputScancodeResult;
}

export const BuilderStepInputScancode: FC<BuilderStepInputScancodeProps> = ({ value, result }) => (
  <Stack direction="row" spacing={1} justifyContent="center">
    {value.step.allowManualInput && <StepTextFieldPlaceholder>{result?.value || null}</StepTextFieldPlaceholder>}
    {!result && (
      <Button disabled>
        <QrCodeScannerIcon />
      </Button>
    )}
  </Stack>
);
