import { Divider, FormHelperText, Stack, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { FC } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { IInputBarcodeRegex } from '@workerbase/domain/input';

import { FormBuilderSettingsTabsWrapper } from 'components/DocumentBuilder/FormBuilderSettingsTabsWrapper';
import { BarcodeFiltersInput } from 'components/Input/BarcodeFiltersInput';
import { FormikToggle } from 'components/Input/Field';
import { FormikObserver } from 'utils/formik';
import { BuilderStepInputScancodeUI, StepListItem } from 'components/DocumentBuilder/types';
import { BuilderStepInputSettingsFormValuesCustomVariable, BuilderStepInputSettingsProps } from '../types';
import {
  BuilderStepSettingsCustomVariable,
  getCustomVariableSchema,
  handleFormReSlugifyOnChange,
} from '../components/BuilderStepSettingsCustomVariable';

interface InputScancodeSettingsFormValues extends BuilderStepInputSettingsFormValuesCustomVariable {
  conditions: IInputBarcodeRegex[];
  openConditions: boolean;
  allowManualInput: boolean;
  required: boolean;
}

const createValidationSchema = ({ intl, stepId, steps }: { intl: IntlShape; stepId: string; steps: StepListItem[] }) =>
  Yup.object({
    conditions: Yup.array().test(
      'conditions',
      intl.formatMessage({ id: 'document.builder.input-scancode.error-message' }),
      function (conditions) {
        const hasError = conditions?.some((item) => !item.value);

        return hasError
          ? this.createError({
              path: 'conditions',
              message: intl.formatMessage({ id: 'document.builder.input-scancode.error-message' }),
            })
          : true;
      },
    ),
    allowManualInput: Yup.boolean().required(),
    required: Yup.boolean().required(),
  }).concat(getCustomVariableSchema(stepId, steps));

export const BuilderStepInputScancodeSettings: FC<BuilderStepInputSettingsProps<BuilderStepInputScancodeUI>> = ({
  value,
  steps,
  onChange,
}) => {
  const intl = useIntl();

  const handleChange =
    (formik: FormikProps<InputScancodeSettingsFormValues>) => (values: InputScancodeSettingsFormValues) => {
      if (!values.openConditions && values.conditions?.length) {
        formik.setFieldValue('conditions', []);
      }
      onChange(
        {
          ...value,
          step: {
            ...value.step,
            allowManualInput: values.allowManualInput,
            conditions: values.conditions,
            required: values.required,
            isCustomVariableName: values.isCustomVariableName,
            variableName: values.variableName,
          },
        },
        handleFormReSlugifyOnChange({
          listItem: value,
          newFormValue: values,
          formik,
        }),
      );
    };

  return (
    <Formik<InputScancodeSettingsFormValues>
      initialValues={{
        openConditions: !!value.step.conditions?.length,
        conditions: value.step.conditions,
        allowManualInput: value.step.allowManualInput,
        required: value.step.required,
        isCustomVariableName: value.step.isCustomVariableName,
        variableName: value.step.variableName,
      }}
      validationSchema={createValidationSchema({ intl, stepId: value.step._id, steps })}
      onSubmit={() => {}}
      validateOnChange
      validateOnMount
      initialTouched={{
        variableName: true,
      }}
    >
      {(formik) => (
        <FormBuilderSettingsTabsWrapper
          header={
            <Typography variant="h6">
              {intl.formatMessage({ id: 'document.builder.input-scancode.settings' })}
            </Typography>
          }
          general={
            <Stack spacing={1} component={Form} id="note-form">
              <FormikObserver<InputScancodeSettingsFormValues> onChange={handleChange(formik)} />
              <FormikToggle name="openConditions" label="from.scancode.specific" />
              <FormHelperText>{intl.formatMessage({ id: 'document.builder.input-scancode.info-text' })}</FormHelperText>
              {formik.values.openConditions && (
                <BarcodeFiltersInput
                  direction="column"
                  filters={formik.values.conditions ?? []}
                  onChange={(updatedFilters) => {
                    formik.setFieldValue('conditions', updatedFilters);
                  }}
                  afterDelete={() => {}}
                />
              )}
              {!!formik.errors.conditions && (
                <FormHelperText error={!!formik.errors.conditions}>
                  <>{formik.errors.conditions}</>
                </FormHelperText>
              )}
              <Divider />
              <FormikToggle name="allowManualInput" label="form.scancode.allow-manual-mobile" />
              <Divider />
              <FormikToggle name="required" label="form-validation.required" />
              <Divider />
              <BuilderStepSettingsCustomVariable />
            </Stack>
          }
        />
      )}
    </Formik>
  );
};
