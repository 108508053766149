/*
 * Util that helps to make sure every item of the Union type is in the array
 * Usage:
 * type Colors = 'red' | 'blue' | 'pink';
 * const missingColors = arrayOfAll<Colors>()(['red', 'blue']); // error
 * const emptyColors = arrayOfAll<Colors>()([]); // error
 * const goodColors = arrayOfAll<Colors>()(['red', 'blue', 'pink']); // compiles
 */
export const arrayOfAll =
  <T>() =>
  <U extends T[]>(array: U & ([T] extends [U[number]] ? unknown : 'Invalid')) =>
    array;
