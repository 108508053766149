import React, { FC } from 'react';
import type { IDocumentBuilderStepInputNumberResult } from '@workerbase/domain/document';
import { BuilderStepInputNumberUI, StepListItem } from 'components/DocumentBuilder/types';
import { StepTextFieldPlaceholder } from '../StepTextInputPlaceholder';

interface BuilderStepInputNumberProps {
  value: StepListItem<BuilderStepInputNumberUI>;
  result?: IDocumentBuilderStepInputNumberResult;
}

export const BuilderStepInputNumber: FC<BuilderStepInputNumberProps> = ({ value, result }) => (
  <StepTextFieldPlaceholder>{result?.value ?? value.step.defaultValue ?? ''}</StepTextFieldPlaceholder>
);
