// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BarcodeFiltersInput-module__inputRow___BTopu {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.BarcodeFiltersInput-module__inputContainer___rSiKI {
  flex: 1 1 500px;
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/BarcodeFiltersInput/BarcodeFiltersInput.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,eAAA;EACA,iBAAA;AAAF","sourcesContent":["// This is some duplicated code with ListOptionsInput.module.scss\n.inputRow {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 12px;\n}\n\n.inputContainer {\n  flex: 1 1 500px;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputRow": `BarcodeFiltersInput-module__inputRow___BTopu`,
	"inputContainer": `BarcodeFiltersInput-module__inputContainer___rSiKI`
};
export default ___CSS_LOADER_EXPORT___;
