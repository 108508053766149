import React, { FC, Fragment, useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { IFilterGroup } from '@workerbase/domain/common';
import { IBuilderStepInputCheckListOption, InputChecklistOperators } from '@workerbase/domain/document';
import { useFormikContext } from 'formik';
import { clone, get } from 'lodash';
import { useIntl } from 'react-intl';
import { InputChecklistSettingsFormValues } from '../../types';
import { FlaggingCriteriaGroup } from './FlaggingCriteriaGroup';
import { DEFAULT_FLAGGING_CRITERIA_CONDITION, DEFAULT_FLAGGING_CRITERIA_GROUP } from './constants';

interface FlaggingCriteriaFormProps {
  name: string;
  options: IBuilderStepInputCheckListOption[];
}

export const FlaggingCriteriaForm: FC<FlaggingCriteriaFormProps> = ({ name, options }) => {
  const intl = useIntl();
  const formik = useFormikContext<InputChecklistSettingsFormValues>();
  const criteriaGroups: IFilterGroup<InputChecklistOperators>[] = get(formik.values, name)?.conditions || [];

  // on options change revalidate the form
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      formik.validateForm();
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [options]);

  const handleAddGroup = () => {
    formik.setFieldValue(`${name}.conditions`, [
      ...criteriaGroups,
      {
        ...DEFAULT_FLAGGING_CRITERIA_GROUP,
        conditions: [
          {
            ...DEFAULT_FLAGGING_CRITERIA_CONDITION,
            name: options[0].value,
          },
        ],
      },
    ]);
  };

  const handleDeleteGroup = (index: number) => {
    const groups = clone(criteriaGroups);
    groups.splice(index, 1);

    if (groups.length) {
      formik.setFieldValue(`${name}.conditions`, groups);
    } else {
      formik.setValues({
        ...formik.values,
        [name]: null,
        hasFlaggingCriteria: false,
      });
    }
  };

  return (
    <>
      <Stack gap={1.5}>
        {criteriaGroups.map((_group, index) => (
          <Fragment key={index}>
            <Typography
              sx={{
                fontFamily: 'Proxima Nova',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '12px',
                letterSpacing: '0.15px',
                textAlign: 'left',
              }}
              color="##00000099"
            >
              {index === 0
                ? intl.formatMessage({ id: 'document.builder.input-step-settings.flag-value-if' })
                : intl.formatMessage({ id: 'document.builder.input-step-settings.or-if' })}
            </Typography>
            <FlaggingCriteriaGroup
              name={`${name}.conditions.${index}`}
              options={options}
              onDelete={() => handleDeleteGroup(index)}
            />
          </Fragment>
        ))}
      </Stack>
      <Button
        startIcon={<Add />}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleAddGroup}
        sx={{ mt: 1.5 }}
      >
        {intl.formatMessage({ id: 'document.add-flagged-value' })}
      </Button>
    </>
  );
};
