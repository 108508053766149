import * as Yup from 'yup';
import { FilterGroupOperator, FilterTypes } from '@workerbase/domain/common';
import { InputChecklistOperators } from '@workerbase/domain/document';
import { StepListItem } from 'components/DocumentBuilder/types';
import { getCustomVariableSchema } from '../components/BuilderStepSettingsCustomVariable';

type FlaggingCriteriaOption = { label: string; value: string };

const conditionSchema = (options: FlaggingCriteriaOption[]) =>
  Yup.object().shape({
    type: Yup.string().oneOf([FilterTypes.CONDITION]).required('form-validation.required'),
    name: Yup.string()
      .oneOf(
        options.map((option) => option.value),
        'form-validation.required',
      )
      .required('form-validation.required'),
    operator: Yup.string().oneOf(Object.values(InputChecklistOperators)).required('form-validation.required'),
    value: Yup.string(),
  });

const groupSchema = (options: FlaggingCriteriaOption[]) =>
  Yup.object().shape({
    type: Yup.string().oneOf([FilterTypes.GROUP]).required(),
    groupOperator: Yup.string().oneOf(Object.values(FilterGroupOperator)).required('form-validation.required'),
    conditions: Yup.array()
      .of(Yup.lazy((value) => (value.type === FilterTypes.GROUP ? groupSchema(options) : conditionSchema(options))))
      .required('form-validation.required')
      .min(1),
  });

export const validationSchema = (stepId: string, steps: StepListItem[], options: FlaggingCriteriaOption[]) =>
  Yup.object({
    multiple: Yup.boolean().required('form-validation.required'),
    minSelect: Yup.number().when('multiple', {
      is: (val) => val === true,
      then: () => Yup.number().integer().min(1).required('form-validation.required'),
      otherwise: () => Yup.number().notRequired(),
    }),
    required: Yup.boolean(),
    hasFlaggingCriteria: Yup.boolean(),
    flaggingCriteria: Yup.object().when('hasFlaggingCriteria', {
      is: (val) => val === true,
      then: () => groupSchema(options),
      otherwise: () => Yup.object().nullable(),
    }),
  }).concat(getCustomVariableSchema(stepId, steps));
