import React, { FC } from 'react';
import { Checkbox, Divider, FormControlLabel, FormGroup, FormHelperText, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import { BuilderStepInputMediaAccepts } from '@workerbase/domain/document';
import { WuiFormikTextField } from '@uiKit';
import { Formik, Form, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { FormikToggle } from 'components/Input/Field';
import { FormikObserver } from 'utils/formik';
import { FormBuilderSettingsTabsWrapper } from 'components/DocumentBuilder/FormBuilderSettingsTabsWrapper';
import { BuilderStepInputMediaUI, StepListItem } from 'components/DocumentBuilder/types';
import {
  BuilderStepSettingsCustomVariable,
  getCustomVariableSchema,
  handleFormReSlugifyOnChange,
} from '../components/BuilderStepSettingsCustomVariable';
import { BuilderStepInputSettingsFormValuesCustomVariable, BuilderStepInputSettingsProps } from '../types';

const validationSchema = (stepId: string, steps: StepListItem[]) =>
  Yup.object({
    audio: Yup.boolean().when(['video', 'image'], {
      is: false,
      then: (schema) => schema.required('form-validation.required'),
    }),
    openMaxCharacters: Yup.boolean(),
    required: Yup.boolean(),
  }).concat(getCustomVariableSchema(stepId, steps));

interface InputMediaConfigFormValues extends BuilderStepInputSettingsFormValuesCustomVariable {
  audio: boolean;
  video: boolean;
  image: boolean;
  multiple: boolean;
  max: number | null;
  required: boolean;
}

export const BuilderStepInputMediaSettings: FC<BuilderStepInputSettingsProps<BuilderStepInputMediaUI>> = ({
  value,
  steps,
  onChange,
}) => {
  const intl = useIntl();

  const checkboxChange = (formik, key) => (_event, checked: boolean) => {
    formik.setFieldValue(key, checked);
  };

  const handleChange = (formik: FormikProps<InputMediaConfigFormValues>) => (values: InputMediaConfigFormValues) => {
    const accepts: BuilderStepInputMediaAccepts[] = [];
    if (values.audio) {
      accepts.push(BuilderStepInputMediaAccepts.Audio);
    }
    if (values.video) {
      accepts.push(BuilderStepInputMediaAccepts.Video);
    }
    if (values.image) {
      accepts.push(BuilderStepInputMediaAccepts.Image);
    }
    if (!values.multiple && values.max !== null) {
      formik.setFieldValue('max', null);
    }
    if (values.multiple && values.max === null) {
      formik.setFieldValue('max', 3);
    }
    onChange(
      {
        ...value,
        step: {
          ...value.step,
          required: values.required,
          max: values.max,
          multiple: values.multiple,
          accept: accepts,
          isCustomVariableName: values.isCustomVariableName,
          variableName: values.variableName,
        },
      },
      handleFormReSlugifyOnChange({
        listItem: value,
        newFormValue: values,
        formik,
      }),
    );
  };

  return (
    <Formik<InputMediaConfigFormValues>
      initialValues={{
        image: value.step.accept.includes(BuilderStepInputMediaAccepts.Image),
        audio: value.step.accept.includes(BuilderStepInputMediaAccepts.Audio),
        video: value.step.accept.includes(BuilderStepInputMediaAccepts.Video),
        multiple: value.step.multiple,
        max: value.step.max,
        required: value.step.required,
        variableName: value.step.variableName,
        isCustomVariableName: value.step.isCustomVariableName,
      }}
      validationSchema={() => validationSchema(value.step._id, steps)}
      onSubmit={() => {}}
      validateOnChange
      validateOnMount
      initialTouched={{
        variableName: true,
      }}
    >
      {(formik) => (
        <Stack spacing={1} component={Form} id="note-form">
          <FormBuilderSettingsTabsWrapper
            header={
              <Typography variant="h6">
                <Typography variant="h6">
                  {intl.formatMessage({ id: 'document.builder.input-media.settings' })}
                </Typography>
              </Typography>
            }
            general={
              <Stack spacing={1}>
                <FormGroup>
                  <FormControlLabel
                    checked={formik.values.audio}
                    onChange={checkboxChange(formik, 'audio')}
                    control={<Checkbox />}
                    label="audio"
                  />
                  <FormControlLabel
                    checked={formik.values.video}
                    onChange={checkboxChange(formik, 'video')}
                    control={<Checkbox />}
                    label="video"
                  />
                  <FormControlLabel
                    checked={formik.values.image}
                    onChange={checkboxChange(formik, 'image')}
                    control={<Checkbox />}
                    label="image"
                  />
                  {value.step.accept.length === 0 && (
                    <FormHelperText error>
                      {intl.formatMessage({ id: 'document.builder.input-media.accept-required' })}
                    </FormHelperText>
                  )}
                </FormGroup>
                <FormikObserver<InputMediaConfigFormValues> onChange={handleChange(formik)} />
                <FormikToggle name="multiple" label="document.builder.input-media.allow-multiple" />
                {formik.values.multiple && (
                  <WuiFormikTextField
                    variant="outlined"
                    type="number"
                    name="max"
                    label="document.builder.input-media.max-uploads"
                    fullWidth
                  />
                )}
                <Divider />
                <FormikToggle name="required" label="form-validation.required" />
                <Divider />
                <BuilderStepSettingsCustomVariable />
              </Stack>
            }
          />
        </Stack>
      )}
    </Formik>
  );
};
