import { IBuilderStepBase } from '@workerbase/domain/document';
import { StepListItem } from 'components/DocumentBuilder/types';
import { FormikProps } from 'formik';

interface ReSlugifyOnChangeProps<T> {
  listItem: StepListItem;
  newFormValue: T;
  formik: FormikProps<T>;
}

export const handleFormReSlugifyOnChange = <T extends Pick<IBuilderStepBase, 'isCustomVariableName' | 'variableName'>>({
  listItem,
  newFormValue,
  formik,
}: ReSlugifyOnChangeProps<T>) =>
  listItem.step.isCustomVariableName !== newFormValue.isCustomVariableName
    ? (reSluggedSteps: StepListItem[]) => {
        const currentStepNewSlug = reSluggedSteps.find((step) => step.step._id === listItem.step._id)?.step
          .variableName;

        if (currentStepNewSlug !== listItem.step.variableName) {
          setTimeout(() => {
            formik.setFieldValue('variableName', currentStepNewSlug);
          });
        }
      }
    : undefined;
