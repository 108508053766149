import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, Stack } from '@mui/material';
import type { IDocumentBuilderStepInputMediaResult } from '@workerbase/domain/document';
import { FileUpload as FileUploadIcon, Camera as CameraIcon } from '@mui/icons-material';
import { WuiMediaDisplay } from '@uiKit';
import { BuilderStepInputMediaUI, StepListItem } from 'components/DocumentBuilder/types';

interface BuilderStepInputMediaProps {
  value: StepListItem<BuilderStepInputMediaUI>;
  result?: IDocumentBuilderStepInputMediaResult;
}

export const BuilderStepInputMedia: FC<BuilderStepInputMediaProps> = ({ result }) => {
  const intl = useIntl();
  return (
    <>
      {result?.value?.length ? (
        <Stack gap={1}>
          {result.value.map((media) => (
            <WuiMediaDisplay id={media._id} disableExpand />
          ))}
        </Stack>
      ) : (
        <Stack gap={2} direction="row">
          <Button startIcon={<FileUploadIcon />} variant="contained" disabled>
            {intl.formatMessage({ id: 'global.upload' })}
          </Button>
          <Button startIcon={<CameraIcon />} variant="contained" disabled>
            {intl.formatMessage({ id: 'global.capture' })}
          </Button>
        </Stack>
      )}
    </>
  );
};
