/* eslint-disable react/jsx-props-no-spreading --- needed in this file */
import React, { FC, useCallback } from 'react';
import { Field, FieldProps } from 'formik';
import { get } from 'lodash';
import type { FormikFieldProps } from '@uiKit/types/FormikField';
import { WuiSelect, WuiSelectProps } from './WuiSelect';

type WuiFormikSelectProps = FormikFieldProps<WuiSelectProps> & {
  showUntouchedError?: boolean;
};

export const WuiFormikSelect: FC<WuiFormikSelectProps> = (props) => {
  const component = useCallback(
    ({
      field,
      form,
      meta,
      children,
      options,
      showUntouchedError = false,
      onChange,
      onBlur,
      ...componentProps
    }: WuiFormikSelectProps & FieldProps) => {
      const isTouched = showUntouchedError || get(form.touched, field.name);
      const errorText = get(form.errors, field.name) as string;
      const showError = !!(isTouched && errorText);

      const handleChange: WuiSelectProps['onChange'] = (e, child) => {
        form.setFieldTouched(props.name, true);
        field.onChange(e);
        onChange?.(e, child);
      };

      const handleBlur: WuiSelectProps['onBlur'] = (e) => {
        field.onChange(e);
        onBlur?.(e);
      };

      const mappedComponentProps = {
        ...componentProps,
        name: field.name,
        value: field.value,
        onChange: handleChange,
        onBlur: handleBlur,
        error: showError,
        helperText: showError ? errorText : componentProps.helperText,
      };

      if (options) {
        return <WuiSelect {...mappedComponentProps} options={options} />;
      }

      return <WuiSelect {...mappedComponentProps}>{children}</WuiSelect>;
    },
    [],
  );

  return <Field component={component} {...props} />;
};
